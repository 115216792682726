import { CapacitorException, WebPlugin } from '@capacitor/core';

import type {
  DeviceInfo,
  HealthAuthStatusResponse,
  HealthDataDaysResult,
  HealthPlugin,
  QueryOptionsRange,
  QueryOptionsSamples,
} from './definitions';

export class HealthWeb extends WebPlugin implements HealthPlugin {
  requestAuthorization(): Promise<void> {
    throw this.unimplemented();
  }

  isHealthDataAvailable(): Promise<{
    availability: 'Available' | 'NotSupported' | 'NotInstalled';
  }> {
    return Promise.resolve({ availability: 'NotSupported' });
  }

  queryDataType(_options: QueryOptionsSamples): Promise<never> {
    throw this.unimplemented();
  }

  getStepsForDays(_options: QueryOptionsRange): Promise<HealthDataDaysResult> {
    throw this.unimplemented();
  }

  getDeviceInfo(): Promise<DeviceInfo> {
    throw this.unimplemented();
  }

  checkAuthStatus(): Promise<HealthAuthStatusResponse> {
    throw this.unimplemented();
  }

  getScheduledBackgroundTask(): Promise<never> {
    throw this.unimplemented();
  }

  runBackgroundTaskHandler(): Promise<never> {
    throw this.unimplemented();
  }

  protected unimplemented(msg?: string): CapacitorException {
    return new CapacitorException(
      `UNIMPLEMENTED: ${msg || 'This method is not implemented on web'}`,
    );
  }
}
